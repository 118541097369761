import { NumberInput } from 'base/src/ui/components/input/NumberInput';
import React from 'react';
import { View } from 'react-native';

import { CONSTANTS } from '../../../../utils/constants';

interface StockNumberInputProps {
  value: number;
  onChange: (value: number) => void;
  onMinusClicked?: () => boolean;
}

export function StockNumberInput({
  value,
  onChange,
  onMinusClicked,
}: StockNumberInputProps) {
  value = value ?? 0;
  return (
    <View
      style={{
        alignItems: 'center',
      }}
    >
      <NumberInput
        layoutMode="button"
        step={CONSTANTS.ORDER_NUMBER_STEP}
        min={0}
        max={100000}
        onMinusClicked={onMinusClicked}
        value={String(value)}
        onChangeText={(text) => onChange(parseInt(text, 10) ?? 0)}
      />
    </View>
  );
}
