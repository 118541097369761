import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { SelectDialog } from 'base/src/ui/dialogs/SelectDialog';
import { useTranslate } from 'base/src/ui/locale/locale';
import { useStoreList } from 'ehawker/src/ui/query/useStoreList';
import _ from 'lodash';
import React, { ReactNode, useState } from 'react';
import { View } from 'react-native';
import { FAB } from 'react-native-paper';

import { NavigationProps, RouteNames } from '../../navigation/Routes';

export function TabContainer({
  children,
  stcode,
  fab,
}: {
  children: ReactNode;
  stcode?: string;
  fab?: boolean;
}) {
  fab = fab ?? true;
  const { t } = useTranslate();
  const navigation = useNavigation<NavigationProps>();
  const [selectDialogVisible, setSelectDialogVisible] = useState(false);
  const { storeList } = useStoreList();
  stcode = _.isEmpty(stcode) ? undefined : stcode;

  function createOrder() {
    if (!stcode) {
      setSelectDialogVisible(true);
      return;
    }
    navigation.navigate(RouteNames.NewOrder, { stcode });
  }

  return (
    <View
      style={{
        height: '100%',
      }}
    >
      {children}
      {fab && (
        <FAB
          label={t('order')}
          style={{
            position: 'absolute',
            bottom: SPACING.s,
            right: SPACING.s,
          }}
          onPress={createOrder}
        />
      )}
      <SelectDialog
        search
        inputList={storeList.map((store) => ({
          label: store._store ?? '',
          value: store._stcode ?? '',
        }))}
        onSelected={(stcode) => {
          setSelectDialogVisible(false);
          navigation.navigate(RouteNames.NewOrder, { stcode: stcode.value });
        }}
        visible={selectDialogVisible}
        onDismiss={() => {
          setSelectDialogVisible(false);
        }}
      />
    </View>
  );
}
