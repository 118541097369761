import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useSnackbar } from 'base/src/ui/helpers/SnackbarHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { OrderRepo } from 'ehawker/src/data/repositories/OrderRepo';
import { OrderSchema } from 'ehawker/src/data/schemas/order/OrderSchema';
import { OrderSummaryRow } from 'ehawker/src/ui/pages/Order/input/OrderSummaryRow';
import { invalidateOrderQueries } from 'ehawker/src/ui/query/useOrderDetail';
import _ from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { Card, Text } from 'react-native-paper';

import { NavigationProps, RouteNames } from '../../../navigation/Routes';

interface DOCardProps {
  order: OrderSchema;
}

export function DoCard({ order }: DOCardProps) {
  const navigation = useNavigation<NavigationProps>();
  const buttonText = order._status === 'new' ? 'Done' : 'Prepare';
  const { addSnackbar } = useSnackbar();
  const { addDialog } = useDialog();
  const { errorDialog } = useErrorHelper();
  const { t } = useTranslate();
  const queryClient = useQueryClient();

  function onPrepareClicked() {
    addDialog({
      mode: 'confirm',
      message: t('confirm_prepare_order'),
      primaryButton: t('ok'),
      secondaryButton: t('cancel'),
      primaryButtonAction: async () => {
        try {
          await OrderRepo.prepareOrder(order);
          invalidateOrderQueries(queryClient, order._osn);
          addSnackbar({
            message: t('order prepared'),
          });
        } catch (error) {
          errorDialog(error as Error);
        }
      },
    });
  }

  return (
    <Card
      onPress={() => {
        if (!_.isNil(order._osn))
          navigation.navigate(RouteNames.DoDetails, {
            orderSn: order._osn,
            doSn: order._dosn,
          });
      }}
      style={{
        flex: 1,
        margin: SPACING.xs,
      }}
    >
      <Card.Content>
        <View style={CommonStyles.container}>
          <Text
            variant="titleMedium"
            style={{
              fontWeight: 'bold',
            }}
          >
            {`${order._store} - ${order._unit} - ${order._status}`}
          </Text>
          <Text variant="bodyMedium">{order._storecentre}</Text>
          <Text variant="bodyMedium">{`${order._owner} ${order._contactno}`}</Text>
          <OrderSummaryRow
            label={t('expected')}
            value={order._expectedDateStr ?? ''}
          />
          <OrderSummaryRow
            label={t('submitted')}
            value={order._submitDateStr ?? ''}
          />
          {/*{order._status === OrderStatus.Delivered && (*/}
          {/*  <OrderSummaryRow*/}
          {/*    label={t('delivered')}*/}
          {/*    value={order._deliveredDateStr ?? ''}*/}
          {/*  />*/}
          {/*)}*/}
        </View>
      </Card.Content>
    </Card>
  );
}
