import { useHeaderHeight } from '@react-navigation/elements';
import { useNavigation } from '@react-navigation/native';
import { CustomError } from 'base/src/data/CustomError';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { KeyboardDismissView } from 'base/src/ui/components/KeyboardDismissView';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { EmployeeUserRepo } from 'ehawker/src/data/repositories/EmployeeUserRepo';
import { UserRepoError } from 'ehawker/src/data/repositories/HawkerUserRepo';
import { UserRepo } from 'ehawker/src/data/repositories/UserRepo';
import { LoginRequestSchema } from 'ehawker/src/data/schemas/auth/LoginRequestSchema';
import { ApkUpdateDialog } from 'ehawker/src/ui/pages/ApkUpdateDialog';
import * as Application from 'expo-application';
import Constants from 'expo-constants';
import { Image } from 'expo-image';
import * as SplashScreen from 'expo-splash-screen';
import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View } from 'react-native';
import {
  Button,
  Checkbox,
  HelperText,
  Text,
  TextInput,
  useTheme,
} from 'react-native-paper';

import { navColorAtom } from '../../navigation/NavBar';
import { NavigationProps, RouteNames } from '../../navigation/Routes';

const log = loggerWithTag('LoginPage.tsx');

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    width: '85%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  loginBtn: {
    marginTop: 20,
  },
  logo: {
    width: '85%',
    height: 100,
  },
  logoContainer: {
    position: 'absolute',
    top: -5,
    width: '100%',
    alignItems: 'center',
  },
  rememberMeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10, // Adjust as needed
  },
});

export default function LoginPage() {
  log.debug('LoginPage rendered');
  const { t } = useTranslate();
  const { errorDialog } = useErrorHelper();
  const { colors } = useTheme();
  const { addDialog } = useDialog();
  const headerHeight = useHeaderHeight();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const backgroundColor = colors.background;
  const setNavColor = useSetAtom(navColorAtom);
  const navigation = useNavigation<NavigationProps>();

  useEffect(() => {
    setNavColor(backgroundColor);
    return () => setNavColor('');
  }, [backgroundColor, setNavColor]);

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  useEffect(() => {
    (async () => {
      const storedCredentials: LoginRequestSchema =
        await UserRepo.getLoginCredentials();
      log.info(
        storedCredentials
          ? JSON.stringify(storedCredentials)
          : 'Credentials not found',
      );
      if (storedCredentials) {
        // Use default values ('') if stored credentials are undefined
        setUsername(storedCredentials._userName ?? '');
        setPassword(storedCredentials._password ?? '');
      }
    })();
  }, []);

  return (
    <KeyboardDismissView>
      <PageContainer
        customStyles={{
          backgroundColor: colors.background,
        }}
      >
        <View
          style={[
            CommonStyles.container,
            styles.outerContainer,
            { backgroundColor: colors.background },
          ]}
        >
          <View style={styles.logoContainer}>
            <Image
              style={styles.logo}
              contentFit="contain"
              source={require('../../../../assets/common/logo.png')}
            />
          </View>
          <View
            style={{
              ...styles.loginContainer,
              marginTop: -headerHeight,
            }}
          >
            <Text
              variant="headlineMedium"
              style={{
                fontWeight: 'bold',
                fontSize: 50,
                marginBottom: 20,
              }}
            >
              eInventory Ops
            </Text>
            <TextInput
              style={styles.textInput}
              mode="flat"
              label={t('username')}
              value={username}
              error={!usernameTouched && usernameError}
              textContentType="username"
              autoCapitalize="none"
              onPressIn={() => setUsernameTouched(true)}
              onFocus={() => setUsernameTouched(true)}
              disabled={loading}
              onChangeText={(text) => {
                setUsername(text);
                setUsernameTouched(true);
              }}
              left={<TextInput.Icon icon="account-circle" />}
            />
            {usernameError && !usernameTouched && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
            <TextInput
              style={styles.textInput}
              mode="flat"
              label={t('password')}
              value={password}
              error={!passwordTouched && passwordError}
              textContentType="password"
              secureTextEntry={!passwordVisible}
              onPressIn={() => setPasswordTouched(true)}
              onFocus={() => setPasswordTouched(true)}
              disabled={loading}
              onChangeText={(text) => {
                setPassword(text);
                setPasswordTouched(true);
              }}
              left={<TextInput.Icon icon="lock" />}
              right={
                <TextInput.Icon
                  icon={passwordVisible ? 'eye-off' : 'eye'}
                  onPress={passwordIconClicked}
                />
              }
            />
            {passwordError && !passwordTouched && (
              <HelperText type="error">{t('field_required')}</HelperText>
            )}
            <View style={styles.rememberMeContainer}>
              <Checkbox.Android
                status={rememberMe ? 'checked' : 'unchecked'}
                onPress={() => setRememberMe(!rememberMe)}
                color={colors.primary}
              />
              <Text>{t('remember_me')}</Text>
            </View>
            <Button
              style={styles.loginBtn}
              mode="contained"
              loading={loading}
              disabled={loading}
              onPress={loginClicked}
            >
              {t('login')}
            </Button>
            {/*<Button onPress={forgetPasswordClicked}>Forget Password</Button>*/}
            {/*<Button*/}
            {/*  style={{*/}
            {/*    marginTop: SPACING.l,*/}
            {/*  }}*/}
            {/*  labelStyle={{*/}
            {/*    textDecorationLine: 'underline',*/}
            {/*  }}*/}
            {/*  onPress={async () => {*/}
            {/*    await Linking.openURL(CONSTANTS.OPS_TUTORIAL_URL);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {`${t('watch_tutorial')} >>`}*/}
            {/*</Button>*/}
          </View>
          <View style={styles.bottomContainer}>
            <Text variant="bodySmall">
              {t('version', {
                version:
                  Application.nativeApplicationVersion ??
                  Constants.expoConfig?.extra?.version ??
                  '-',
                build:
                  Application.nativeBuildVersion ??
                  Constants.expoConfig?.extra?.build ??
                  '-',
              })}
            </Text>
            <Text variant="bodySmall">
              {t('copyright_line1', { year: new Date().getFullYear() }) +
                ' ' +
                t('copyright_line2')}
            </Text>
          </View>
        </View>
        <ApkUpdateDialog />
      </PageContainer>
    </KeyboardDismissView>
  );

  async function loginClicked() {
    log.info('Login button clicked');
    if (username.trim().length === 0) {
      log.info('Username is empty');
      setUsernameError(true);
      setUsernameTouched(false);
      return;
    }
    if (password.trim().length === 0) {
      log.info('Password is empty');
      setPasswordError(true);
      setPasswordTouched(false);
      return;
    }
    setLoading(true);
    await login();
  }

  async function forgetPasswordClicked() {
    log.info('Forget Passwsord button clicked');
    navigation.navigate(RouteNames.ForgetPassword);
  }

  function passwordIconClicked() {
    log.info('Password visibility icon clicked');
    setPasswordVisible(!passwordVisible);
  }

  async function login() {
    log.info('LoginPage, navigate to dashboard page');
    Keyboard.dismiss();
    try {
      await EmployeeUserRepo.login({
        _userName: username,
        _password: password,
      });
    } catch (error) {
      if (error instanceof CustomError) {
        switch (error.type) {
          case UserRepoError.INVALID_PASSWORD:
            return addDialog({
              message: t('invalid_password'),
              mode: 'error',
            });
        }
      }
      errorDialog(error as Error);
    } finally {
      log.info('save or remove login credential');
      const credentials = {
        _userName: rememberMe ? username : '',
        _password: rememberMe ? password : '',
      };
      await UserRepo.setLoginCredentials(credentials);
      setLoading(false);
    }
  }
}
