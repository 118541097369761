import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NotificationPage } from 'ehawker/src/data/schemas/PushNotificationSchema';
import { useNotification } from 'ehawker/src/ui/hooks/useNotification';
import { useUser } from 'ehawker/src/ui/hooks/useUser';
import { useWebBackButtonHandler } from 'ehawker/src/ui/pages/Order/hooks/useWebBackButtonHandler';
import * as Notifications from 'expo-notifications';
import _ from 'lodash';
import React, { FunctionComponent, useCallback } from 'react';

import { NavBar, NavBarProps } from './NavBar';
import { NavigationProps, RouteNames, ROUTES } from './Routes';

const Stack = createNativeStackNavigator();

// This handler determines how your app handles notifications that come in while the app is foregrounded.
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
    priority: Notifications.AndroidNotificationPriority.MAX,
  }),
});

export function NavigatorWrapper() {
  const header = useCallback((props: NavBarProps) => <NavBar {...props} />, []);
  const user = useUser();
  const routes = Object.values(ROUTES)
    .filter((route) => !_.isEmpty(route.name))
    .filter((route) => !_.isNil(user) === route.authRequired);
  const navigation = useNavigation<NavigationProps>();
  useWebBackButtonHandler();

  useNotification((notification) => {
    switch (notification.PAGE) {
      case NotificationPage.OrderDetail: {
        const orderSn = parseInt(notification.VALUE ?? '0', 10);
        if (orderSn !== 0) {
          navigation.navigate(RouteNames.DoDetails, {
            orderSn,
          });
        }
        break;
      }
    }
  });

  return (
    <Stack.Navigator
      initialRouteName={RouteNames.Dashboard}
      screenOptions={{
        header,
      }}
    >
      {routes.map((route) => (
        <Stack.Screen
          key={route.name}
          name={route.name}
          component={route.component as FunctionComponent}
        />
      ))}
    </Stack.Navigator>
  );
}
