import { loggerWithTag } from 'base/src/utils/log';
import React, { useEffect, useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { CONSTANTS } from '../../utils/constants';

const log = loggerWithTag('ExpandableView.tsx');

const styles = StyleSheet.create({
  animatedView: {
    overflow: 'hidden',
  },
});

interface ExpandableViewProps {
  viewKey: string;
  expanded: boolean;
  initialExpanded: boolean;
  expandedContent: JSX.Element;
  collapsedContent: JSX.Element;
  containerStyle?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
}

// LayoutAnimation is having problem with react-native-paper TextInput and
// causing iOS shadow artifact so we use react-native-reanimated instead
// https://github.com/callstack/react-native-paper/issues/2592
// https://github.com/facebook/react-native/issues/6151
export function ExpandableView({
  viewKey,
  expanded,
  initialExpanded,
  expandedContent,
  collapsedContent,
  containerStyle,
  innerContainerStyle,
}: ExpandableViewProps) {
  log.debug('ExpandableView rendered');

  const [firstAnimationCompleted, setFirstAnimationCompleted] = useState(false);
  const [expandedHeight, setExpandedHeight] = useState(0);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const animatedHeight = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    height: animatedHeight.value,
  }));
  const animatedExpandedOpacity = useSharedValue(initialExpanded ? 1 : 0);
  const animatedExpandedStyle = useAnimatedStyle(() => ({
    opacity: animatedExpandedOpacity.value,
  }));
  const animatedCollapsedOpacity = useSharedValue(initialExpanded ? 0 : 1);
  const animatedCollapsedStyle = useAnimatedStyle(() => ({
    opacity: animatedCollapsedOpacity.value,
  }));

  useEffect(() => {
    animatedHeight.value = withTiming(
      expanded ? expandedHeight : collapsedHeight,
      {
        duration: CONSTANTS.ANIMATION_DURATION,
      },
    );
    animatedExpandedOpacity.value = withTiming(expanded ? 1 : 0, {
      duration: CONSTANTS.ANIMATION_DURATION,
    });
    animatedCollapsedOpacity.value = withTiming(expanded ? 0 : 1, {
      duration: CONSTANTS.ANIMATION_DURATION,
    });
    setFirstAnimationCompleted(true);
  }, [expanded]);

  useEffect(() => {
    if (firstAnimationCompleted) {
      animatedHeight.value = expanded ? expandedHeight : collapsedHeight;
    }
  }, [expandedHeight, collapsedHeight]);

  return (
    <Animated.View
      key={viewKey}
      style={[styles.animatedView, animatedStyle, containerStyle]}
    >
      <Animated.View
        style={[
          animatedExpandedStyle,
          innerContainerStyle,
          {
            position: 'absolute',
            width: '100%',
            // @ts-ignore
            pointerEvents: expanded ? 'auto' : 'none',
          },
        ]}
        onLayout={(event) => {
          const height = event.nativeEvent.layout.height;
          setExpandedHeight(height);
        }}
      >
        {expandedContent}
      </Animated.View>
      <Animated.View
        style={[
          animatedCollapsedStyle,
          innerContainerStyle,
          {
            position: 'absolute',
            width: '100%',
            // @ts-ignore
            pointerEvents: expanded ? 'none' : 'auto',
          },
        ]}
        onLayout={(event) => {
          const height = event.nativeEvent.layout.height;
          setCollapsedHeight(height);
        }}
      >
        {collapsedContent}
      </Animated.View>
    </Animated.View>
  );
}
