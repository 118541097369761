import { FlashList } from '@shopify/flash-list';
import { OrderListRequestSchema } from 'ehawker/src/data/schemas/order/OrderListRequestSchema';
import { OrderStatus } from 'ehawker/src/data/schemas/order/OrderSchema';
import { useOrderList } from 'ehawker/src/ui/query/useOrderList';
import { useSetAtom } from 'jotai';
import _ from 'lodash';
import React, { memo, useEffect } from 'react';

import { DoCard } from './DoCard';
import { doListFilterExpandedAtom } from './DoListFilter';

interface DoListTabScreenProps {
  label: string;
  orderListRequest: OrderListRequestSchema;
  badge?: number;
  setBadge: (count: number, status: OrderStatus) => void;
}

export const DoListTabScreen = memo(function DoListTabScreen({
  label,
  orderListRequest,
  setBadge,
}: DoListTabScreenProps) {
  const { orders, ordersCount, ordersLoading, refreshOrders } =
    useOrderList(orderListRequest);
  const setFilterExpanded = useSetAtom(doListFilterExpandedAtom);

  useEffect(() => {
    if (!_.isNil(orderListRequest._status)) {
      setBadge(ordersCount, orderListRequest._status);
    }
  }, [ordersCount]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshOrders;
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <FlashList
      estimatedItemSize={6}
      onRefresh={refreshOrders}
      refreshing={ordersLoading}
      data={orders}
      renderItem={({ item }) => <DoCard order={item} />}
      keyExtractor={(item) => String(item._osn)}
      onScroll={() => setFilterExpanded(false)}
    />
  );
});
