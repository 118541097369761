import {
  AppType,
  CONSTANTS as BASE_CONSTANTS,
} from 'ehawker/src/utils/constants';

BASE_CONSTANTS.APP_TYPE = AppType.Ops;

export const CONSTANTS = {
  ...BASE_CONSTANTS,
};
