import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Card, TouchableRipple } from 'react-native-paper';

const log = loggerWithTag('DashboardCount.tsx');

interface DashboardCountProps {
  title: string;
  count: number;
  visible?: boolean;
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    height: '100%',
  },
  cardTouchable: {
    borderRadius: SPACING.xs,
    height: '100%',
  },
  cardContent: {
    height: '100%',
  },
});

export function DashboardCount({
  title,
  count,
  visible = true,
}: DashboardCountProps) {
  const { t } = useTranslate();
  const navigation = useNavigation();

  return (
    <Card
      style={{
        flex: 1,
        margin: SPACING.xs,
      }}
      contentStyle={styles.cardContent}
    >
      <TouchableRipple
        borderless
        style={styles.cardTouchable}
        onPress={clicked}
      >
        <Card.Title
          title={String(count)}
          titleVariant="titleLarge"
          titleStyle={{
            fontWeight: 'bold',
          }}
          subtitle={t(getTitleTranslationKeyForName(title))}
          style={styles.cardContent}
        />
      </TouchableRipple>
    </Card>
  );

  // TODO: dont hard code
  function clicked() {
    log.info(`Clicked ${String(title)} dashboard count`);
    let num: number = 0; // Default index

    if (title === 'Prepared') {
      num = 1;
    } else if (title === 'Delivered') {
      num = 2;
    } else if (title === 'Completed') {
      num = 3;
    } else if (title === 'Cancelled') {
      num = 4;
    }

    log.info(`Clicked ${String(num)} index count`);
    // @ts-ignore
    navigation.navigate('DO', { index: num });
    //navigation.navigate(RouteNames.DoListTab, { index: num });
  }

  // TODO: cleanup
  // Helper function to map 'name' to translation keys
  function getTitleTranslationKeyForName(name: string) {
    switch (name) {
      case 'ALL':
        return 'all';
      case 'New':
        return 'new';
      case 'Prepared':
        return 'prepared';
      case 'Delivered':
        return 'delivered';
      case 'Cancelled':
        return 'cancelled';
      // Add more cases as needed
      default:
        return name; // Use the name itself if not matched to a specific translation key
    }
  }
}
