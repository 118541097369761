import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { PasswordInput } from 'base/src/ui/components/input/PasswordInput';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { EmployeeUserRepo } from 'ehawker/src/data/repositories/EmployeeUserRepo';
import {
  ChangePasswordRequest,
  ChangePasswordRequestSchema,
} from 'ehawker/src/data/schemas/auth/ChangePasswordRequestSchema';
import { Image } from 'expo-image';
import * as SplashScreen from 'expo-splash-screen';
import { useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { Button, Text, useTheme } from 'react-native-paper';

import { navColorAtom } from '../../navigation/NavBar';
import { NavigationProps } from '../../navigation/Routes';

const log = loggerWithTag('ChangePasswordPage.tsx');

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginContainer: {
    width: '85%',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    marginTop: 10,
  },
  submitBtn: {
    marginTop: 20,
  },
  logo: {
    width: '85%',
    height: 100,
  },
  logoContainer: {
    position: 'absolute',
    top: -10,
    width: '100%',
    alignItems: 'center',
  },
  rememberMeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10, // Adjust as needed
  },
});

export default function ChangePasswordPage() {
  log.debug('ChangePasswordPage rendered');
  const { t } = useTranslate();
  const { errorDialog } = useErrorHelper();
  const { colors } = useTheme();
  const { addDialog } = useDialog();

  const [loading, setLoading] = useState(false);

  const backgroundColor = colors.background;
  const setNavColor = useSetAtom(navColorAtom);
  const navigation = useNavigation<NavigationProps>();
  const form = useForm<ChangePasswordRequest>({
    resolver: zodResolver(ChangePasswordRequestSchema),
  });
  const { control, getValues, clearErrors, reset, trigger, formState } =
    useForm<ChangePasswordRequest>({
      resolver: zodResolver(ChangePasswordRequestSchema),
    });

  useEffect(() => {
    setNavColor(backgroundColor);
    return () => setNavColor('');
  }, [backgroundColor, setNavColor]);

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const onSubmitPressed = useCallback(() => {
    log.info('onSubmitPressed');
    trigger().then((isValid) => {
      if (!isValid) return;
      setSubmitting(true);
      EmployeeUserRepo.changePassword(getValues())
        .then(() => {
          addDialog({
            title: t('success'),
            message: t('password_changed'),
            onDismiss: () => {
              navigation.goBack();
            },
          });
        })
        .catch(errorDialog)
        .finally(() => setSubmitting(false));
    });
  }, [addDialog, errorDialog, getValues, reset, t, trigger]);

  return (
    <FormProvider {...form}>
      <PageContainer
        customStyles={{
          backgroundColor: colors.background,
        }}
      >
        <View
          style={[
            CommonStyles.container,
            styles.outerContainer,
            { backgroundColor: colors.background },
          ]}
        >
          <View style={styles.logoContainer}>
            <Image
              style={styles.logo}
              contentFit="contain"
              source={require('../../../../assets/common/logo.png')}
            />
          </View>
          <View style={styles.loginContainer}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  label={t('current_password')}
                  onChangeText={(text) => {
                    onChange(text);
                    clearErrors('_oldPassword');
                  }}
                  value={value}
                  style={styles.textInput}
                  inputError={formState.errors._oldPassword}
                  disabled={loading}
                />
              )}
              name="_oldPassword"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  label={t('new_password')}
                  onChangeText={(text) => {
                    onChange(text);
                    clearErrors('_newPassword');
                  }}
                  value={value}
                  style={styles.textInput}
                  inputError={formState.errors._newPassword}
                  disabled={loading}
                />
              )}
              name="_newPassword"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <PasswordInput
                  label={t('confirm_password')}
                  onChangeText={(text) => {
                    onChange(text);
                    clearErrors('_confirmedPassword');
                  }}
                  value={value}
                  style={styles.textInput}
                  inputError={formState.errors._confirmedPassword}
                  disabled={loading}
                />
              )}
              name="_confirmedPassword"
            />
            <Button
              style={styles.submitBtn}
              mode="contained"
              loading={loading}
              disabled={loading}
              onPress={onSubmitPressed}
            >
              {t('submit')}
            </Button>
          </View>
          {/* TODO: deduplicate changepasswordpage and dashboardprofile change password */}
          <View
            style={{
              marginTop: SPACING.s,
            }}
          >
            <Text style={{ color: colors.outline }} variant="labelLarge">
              Notes
            </Text>
            <Text style={{ color: colors.outline }} variant="labelLarge">
              {' '}
              • Password must contain minimum 8 characters.
            </Text>
            <Text style={{ color: colors.outline }} variant="labelLarge">
              {' '}
              • Password must contain Uppercase and Lowercase Characters.
            </Text>
            <Text style={{ color: colors.outline }} variant="labelLarge">
              {' '}
              • Password must contain Numbers.
            </Text>
            <Text style={{ color: colors.outline }} variant="labelLarge">
              {' '}
              • Password must contain Special Character.
            </Text>
          </View>
        </View>
      </PageContainer>
    </FormProvider>
  );
}
