import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { useQueryClient } from '@tanstack/react-query';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { PageContainer } from 'base/src/ui/components/PageContainer';
import { DateInput } from 'base/src/ui/components/input/DateInput';
import { SelectTextInput } from 'base/src/ui/components/input/SelectTextInput';
import { SelectDialog } from 'base/src/ui/dialogs/SelectDialog';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useSnackbar } from 'base/src/ui/helpers/SnackbarHelper';
import { useConfirmExit } from 'base/src/ui/hooks/useConfirmExit';
import { useTranslate } from 'base/src/ui/locale/locale';
import { StockRepo } from 'ehawker/src/data/repositories/StockRepo';
import {
  StockReceiveSchema,
  StockReceiveZodSchema,
} from 'ehawker/src/data/schemas/stock/StockReceiveSchema';
import { useItemDetail } from 'ehawker/src/ui/query/useItemDetail';
import { StockListKey } from 'ehawker/src/ui/query/useStockList';
import { useStockListBySupplier } from 'ehawker/src/ui/query/useStockListBySupplier';
import { useSupplierList } from 'ehawker/src/ui/query/useSupplierList';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { Button, Card, TextInput } from 'react-native-paper';

import { StockItemInput } from './StockItemInput';
import { RouteNames, RouteParams } from '../../../navigation/Routes';

export function StockReceivePage({
  route,
}: StackScreenProps<RouteParams, RouteNames.StockReceive>) {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const { errorDialog } = useErrorHelper();
  const [submitting, setSubmitting] = useState(false);
  const [addItemDialogVisible, setAddItemDialogVisible] = useState(false);
  const { i18n, t } = useTranslate();
  const itemcode = route.params?._itemcode;
  const { itemDetail } = useItemDetail(itemcode);
  const { supplierList } = useSupplierList();
  const supplierInputList = supplierList.map((supplier) => ({
    label: supplier._supplier,
    value: supplier._suppliersn,
  }));
  const form = useForm<StockReceiveSchema>({
    resolver: zodResolver(StockReceiveZodSchema),
    defaultValues: {
      _stockTransModel: {
        _podate: moment().format('YYYY-MM-DDTHH:mm'),
        _transdate: moment().format('YYYY-MM-DDTHH:mm'),
      },
      _itemQtyUpdateList: [],
    },
  });
  const watchedItemQtyUpdateList = form.watch('_itemQtyUpdateList') ?? [];
  const watchedSupplierSn = form.watch('_stockTransModel._suppliersn') ?? 0;
  const { stockList } = useStockListBySupplier(watchedSupplierSn);
  const itemInputList = stockList.map((stock) => ({
    label: stock._item,
    value: stock._itemsn,
  }));
  const filteredItemInputList = itemInputList.filter(
    (item) =>
      !watchedItemQtyUpdateList.find(
        (newItem) => newItem._itemsn === item.value,
      ),
  );
  const removeExitListener = useConfirmExit();
  const { addSnackbar } = useSnackbar();
  const { addDialog } = useDialog();
  const [skipClearItems, setSkipClearItems] = useState(false);

  useEffect(() => {
    if (skipClearItems) {
      setSkipClearItems(false);
      return;
    }
    form.setValue('_itemQtyUpdateList', []);
  }, [watchedSupplierSn]);

  useEffect(() => {
    if (!_.isNil(itemDetail)) {
      form.setValue('_stockTransModel._suppliersn', itemDetail?._suppliersn);
      setSkipClearItems(true);
      form.setValue('_itemQtyUpdateList', [
        {
          _itemcode: itemDetail?._itemcode,
          _item: itemDetail?._item,
          _itemsn: itemDetail?._itemsn,
          _qtychange: 1,
        },
      ]);
    }
  }, [itemDetail]);

  function addItem(itemSn: number) {
    const item = stockList.find((stock) => stock._itemsn === itemSn);
    if (_.isEmpty(item)) return;
    form.setValue('_itemQtyUpdateList', [
      ...(form.getValues()._itemQtyUpdateList ?? []),
      {
        _item: item._item,
        _itemcode: item._itemcode,
        _itemsn: itemSn,
        _qtychange: 1,
      },
    ]);
  }

  function confirmBeforeSubmit({
    onConfirm,
  }: {
    onConfirm: () => Promise<void>;
  }) {
    addDialog({
      mode: 'confirm',
      message: t('confirm_submit'),
      primaryButton: t('ok'),
      secondaryButton: t('cancel'),
      primaryButtonAction: () => onConfirm(),
    });
  }

  return (
    <PageContainer>
      <FormProvider {...form}>
        <ScrollView>
          <View style={{ gap: SPACING.s }}>
            <Card
              style={{
                marginTop: SPACING.s,
              }}
            >
              <Card.Content>
                <View
                  style={{
                    gap: SPACING.s,
                  }}
                >
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <SelectTextInput
                        search
                        label={t('supplier')}
                        mode="outlined"
                        inputList={supplierInputList}
                        value={value}
                        onSelected={(item) => onChange(item.value)}
                      />
                    )}
                    name="_stockTransModel._suppliersn"
                  />
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label={t('trans_date')}
                        value={value ? moment(value).toDate() : undefined}
                        onChange={(newDate) =>
                          onChange(
                            newDate
                              ? moment(newDate).format('YYYY-MM-DDTHH:mm')
                              : undefined,
                          )
                        }
                      />
                    )}
                    name="_stockTransModel._transdate"
                  />
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label={t('po_num')}
                        mode="outlined"
                        value={value ?? ''}
                        onChangeText={onChange}
                      />
                    )}
                    name="_stockTransModel._ponum"
                  />
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        label={t('po_date')}
                        value={value ? moment(value).toDate() : undefined}
                        onChange={(newDate) =>
                          onChange(
                            newDate
                              ? moment(newDate).format('YYYY-MM-DDTHH:mm')
                              : undefined,
                          )
                        }
                      />
                    )}
                    name="_stockTransModel._podate"
                  />
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label={t('do_num')}
                        mode="outlined"
                        value={value ?? ''}
                        onChangeText={onChange}
                      />
                    )}
                    name="_stockTransModel._donum"
                  />
                  <Controller
                    control={form.control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        label={t('remarks')}
                        mode="outlined"
                        value={value ?? ''}
                        onChangeText={onChange}
                      />
                    )}
                    name="_stockTransModel._remarks"
                  />
                </View>
              </Card.Content>
            </Card>
            <View
              style={[
                CommonStyles.row,
                {
                  justifyContent: 'space-between',
                },
              ]}
            >
              <View />
              <Button onPress={() => setAddItemDialogVisible(true)}>
                {t('add_item')}
              </Button>
              <SelectDialog
                search
                inputList={filteredItemInputList}
                visible={addItemDialogVisible}
                onDismiss={() => setAddItemDialogVisible(false)}
                onSelected={(selectedItem) => {
                  setAddItemDialogVisible(false);
                  addItem(selectedItem.value);
                }}
              />
            </View>
            {watchedItemQtyUpdateList.map((item) => (
              <StockItemInput itemsn={item._itemsn ?? 0} key={item._itemsn} />
            ))}
            <Button
              disabled={submitting}
              loading={submitting}
              mode="contained"
              onPress={async () => {
                confirmBeforeSubmit({
                  onConfirm: async () => {
                    setSubmitting(true);
                    try {
                      await StockRepo.submitStockReceive(form.getValues());
                      await queryClient.invalidateQueries({
                        queryKey: [StockListKey],
                      });
                      addSnackbar({
                        message: t('stock_receive_success'),
                      });
                      removeExitListener();
                      navigation.goBack();
                    } catch (e) {
                      errorDialog(e as Error);
                    } finally {
                      setSubmitting(false);
                    }
                  },
                });
              }}
            >
              Submit
            </Button>
          </View>
        </ScrollView>
      </FormProvider>
    </PageContainer>
  );
}
