import { useNavigation } from '@react-navigation/native';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { StockRequestSchema } from 'ehawker/src/data/schemas/stock/StockRequestSchema';
import { StockSortType, useStockList } from 'ehawker/src/ui/query/useStockList';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { FAB, IconButton, Menu, Searchbar } from 'react-native-paper';

import { InventoryItem } from './InventoryItem';
import { NavigationProps, RouteNames } from '../../../navigation/Routes';
import { TabContainer } from '../TabContainer';

export function InventoryTab() {
  const navigation = useNavigation<NavigationProps>();
  const { t } = useTranslate();
  const [stockListRequest, setStockListRequest] = useState<StockRequestSchema>(
    {},
  );
  const {
    stockListLoading,
    stockList,
    refreshStockList,
    fetchMoreStockItems,
    stockSearchText,
    searchStock,
    sortStock,
  } = useStockList(stockListRequest);
  const [sortVisible, setSortVisible] = useState(false);

  return (
    <TabContainer fab={false}>
      <Searchbar
        style={{
          margin: SPACING.s,
        }}
        placeholder={t('search')}
        right={() => (
          <Menu
            visible={sortVisible}
            onDismiss={() => setSortVisible(false)}
            anchor={
              <IconButton icon="sort" onPress={() => setSortVisible(true)} />
            }
          >
            <Menu.Item
              title={t('alphabetical')}
              onPress={() => {
                sortStock(StockSortType.alphabetical);
                setSortVisible(false);
              }}
            />
            <Menu.Item
              title={t('most_stock')}
              onPress={() => {
                sortStock(StockSortType.mostStock);
                setSortVisible(false);
              }}
            />
            <Menu.Item
              title={t('least_stock')}
              onPress={() => {
                sortStock(StockSortType.leastStock);
                setSortVisible(false);
              }}
            />
          </Menu>
        )}
        onChangeText={searchStock}
        value={stockSearchText}
      />
      <FlatList
        data={stockList}
        refreshing={stockListLoading}
        onRefresh={refreshStockList}
        renderItem={({ item }) => <InventoryItem stock={item} />}
        onEndReached={() => fetchMoreStockItems()}
        contentContainerStyle={{
          paddingBottom: 70,
        }}
        keyExtractor={(item) => item._itemcode}
      />
      <FAB
        label={t('stock_receive')}
        style={{
          position: 'absolute',
          bottom: SPACING.s,
          right: SPACING.s,
        }}
        onPress={() => navigation.navigate(RouteNames.StockReceive, {})}
      />
    </TabContainer>
  );
}
