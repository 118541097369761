import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { StockReceiveSchema } from 'ehawker/src/data/schemas/stock/StockReceiveSchema';
import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';

import { StockNumberInput } from './StockNumberInput';
import { CONSTANTS } from '../../../../utils/constants';

interface StockItemInputProps {
  itemsn: number;
}

export function StockItemInput({ itemsn }: StockItemInputProps) {
  const { colors } = useTheme();
  const { addDialog } = useDialog();
  const form = useFormContext<StockReceiveSchema>();
  const stockItemList = form.getValues('_itemQtyUpdateList') ?? [];
  const stockItemIndex = stockItemList.findIndex(
    (stockItem) => stockItem._itemsn === itemsn,
  );
  const stockItem = stockItemList[stockItemIndex];

  function onAmountChange(amount: number) {
    stockItemList[stockItemIndex]._qtychange = amount;
    form.setValue('_itemQtyUpdateList', stockItemList);
  }

  function confirmRemove() {
    addDialog({
      mode: 'warning',
      message: `Are you sure you want to remove ${stockItem._item}?`,
      primaryButton: 'OK',
      primaryButtonAction: () => {
        form.setValue(
          '_itemQtyUpdateList',
          stockItemList.filter((item) => item._itemsn !== stockItem._itemsn),
        );
      },
      secondaryButton: 'Cancel',
    });
  }

  return (
    <View
      style={[
        CommonStyles.row,
        {
          margin: SPACING.xs,
          gap: SPACING.l,
          alignItems: 'center',
        },
      ]}
    >
      <View
        style={{
          flex: 1,
        }}
      >
        <Text variant="titleMedium">{stockItem._itemcode}</Text>
        <Text variant="titleMedium">{stockItem._item}</Text>
        <Text
          style={{
            color: colors.outline,
          }}
          variant="titleSmall"
        >
          {stockItem._remarks}
        </Text>
      </View>
      <StockNumberInput
        onMinusClicked={() => {
          if (
            _.isNil(stockItem._qtychange) ||
            stockItem._qtychange <= CONSTANTS.ORDER_NUMBER_STEP
          ) {
            confirmRemove();
            return false;
          }
          return true;
        }}
        onChange={onAmountChange}
        value={stockItem?._qtychange ?? 0}
      />
    </View>
  );
}
