import { RouteProp, useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { openDrawerAtom } from 'base/src/ui/components/DrawerPageContainer';
import { LanguageDialog } from 'base/src/ui/dialogs/LanguageDialog';
import { useDialog } from 'base/src/ui/helpers/DialogHelper';
import { useErrorHelper } from 'base/src/ui/helpers/ErrorHelper';
import { useFontConfig } from 'base/src/ui/hooks/useFontConfig';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { EmployeeUserRepo } from 'ehawker/src/data/repositories/EmployeeUserRepo';
import { LocaleLabels, TranslationKeys } from 'ehawker/src/ui/locale/locale';
import { Image } from 'expo-image';
import { atom, useAtom, useAtomValue } from 'jotai';
import React, { memo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Appbar, Menu, SegmentedButtons, useTheme } from 'react-native-paper';

import { getRouteByName, NavigationProps, RouteNames, ROUTES } from './Routes';
import { deleteData } from '../../data/actions/DeleteData';
import { fetchData } from '../../data/actions/FetchData';
import { FLAG } from '../../utils/flag';
import { eventEmitter } from '../App';

const log = loggerWithTag('NavBar.tsx');

export const navColorAtom = atom('');

const styles = StyleSheet.create({
  appbarContent: {
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
});

export type NavBarProps = {
  navigation: any;
  route: RouteProp<Record<string, object | undefined>, string>;
  options: any;
  back?: { title: string };
};

type MenuItem = 'refresh' | 'logout' | 'profile';

function DashboardIcon() {
  return (
    <Image
      contentFit="contain"
      contentPosition={{
        top: 0,
        left: 0,
      }}
      source={require('../../../assets/common/logo.png')}
      style={{ height: 30, width: '100%' }}
    />
  );
}

export const NavBar = memo(
  function NavBar({ route, options }: NavBarProps) {
    log.debug(`NavBar rendered -> ${route.name}`);

    const queryClient = useQueryClient();
    const { t } = useTranslate();
    const { errorSnackbar } = useErrorHelper();
    const [openDrawer, setOpenDrawer] = useAtom(openDrawerAtom);
    const navigation = useNavigation<NavigationProps>();
    const currentRoute = getRouteByName(route.name);
    const isDashboard = currentRoute === ROUTES.Dashboard;
    const title = currentRoute?.title ? getTitle(currentRoute.title) : '';
    const [languageDialogVisible, setLanguageDialogVisible] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const navColor = useAtomValue(navColorAtom);
    const { colors } = useTheme();
    const { fontMultiplier, setFontMultiplier } = useFontConfig();
    const { addDialog } = useDialog();

    function getTitle(routeTitle: TranslationKeys) {
      return 'eInventory Ops';
      // if (routeTitle === ROUTES.Dashboard.title) {
      //   return <DashboardIcon />;
      // }
      // return routeTitle ? t(routeTitle) : '';
    }

    return (
      <>
        <Appbar.Header
          style={{ backgroundColor: navColor || colors.elevation.level2 }}
          statusBarHeight={0}
        >
          {currentRoute?.backButton && (
            <Appbar.BackAction onPress={navigation.goBack} />
          )}
          {isDashboard && FLAG.DRAWER && !currentRoute?.backButton && (
            <Appbar.Action
              icon="menu"
              onPress={() => setOpenDrawer(!openDrawer)}
            />
          )}
          {/*@ts-ignore*/}
          <Appbar.Content titleStyle={styles.appbarContent} title={title} />
          {FLAG.TRANSLATION && (
            <Appbar.Action icon="web" onPress={languageClicked} />
          )}

          {isDashboard && (
            <Menu
              style={{
                width: 200,
              }}
              visible={menuVisible}
              onDismiss={() => setMenuVisible(false)}
              anchor={
                <Appbar.Action icon="dots-vertical" onPress={menuClicked} />
              }
            >
              <Menu.Item
                title={t('refresh')}
                onPress={() => menuItemClicked('refresh')}
              />
              <Menu.Item
                title={t('profile')}
                onPress={() => menuItemClicked('profile')}
              />
              <Menu.Item title={t('font_size')} />
              <SegmentedButtons
                style={[
                  {
                    marginHorizontal: SPACING.s,
                  },
                  CommonStyles.container,
                ]}
                theme={{
                  colors: {
                    secondaryContainer: colors.primary,
                    onSecondaryContainer: colors.onPrimary,
                  },
                }}
                value={fontMultiplier.toString()}
                onValueChange={(value) => setFontMultiplier(parseFloat(value))}
                buttons={[
                  {
                    value: '1',
                    label: t('small'),
                  },
                  {
                    value: '1.2',
                    label: t('large'),
                  },
                ]}
              />
              {/*<Menu.Item*/}
              {/*  title={t('tutorial_video')}*/}
              {/*  onPress={async () => {*/}
              {/*    await Linking.openURL(CONSTANTS.OPS_TUTORIAL_URL);*/}
              {/*  }}*/}
              {/*/>*/}
              <Menu.Item
                title={t('logout')}
                onPress={() => menuItemClicked('logout')}
              />
            </Menu>
          )}
        </Appbar.Header>
        <LanguageDialog
          localeLabels={LocaleLabels}
          visible={languageDialogVisible}
          onDialogClosed={() => setLanguageDialogVisible(false)}
        />
      </>
    );

    function languageClicked() {
      log.info('language button clicked');
      setLanguageDialogVisible(true);
    }

    function menuClicked() {
      log.info('menu button clicked');
      setMenuVisible(true);
    }

    // TODO: add a custom hook to add menu item which utilize useEffect
    //  for clearing the menu after unmount
    function menuItemClicked(item: MenuItem) {
      log.info(`menu item clicked: ${item}`);
      setMenuVisible(false);
      switch (item) {
        case 'refresh':
          refreshClicked();
          break;
        case 'logout':
          logoutClicked();
          break;
        case 'profile':
          profileClicked();
          break;
      }
    }

    function refreshClicked() {
      log.info('refresh clicked');
      fetchData().catch(errorSnackbar);
      eventEmitter.emit('nav_refresh');
      queryClient.invalidateQueries();
    }

    async function logoutClicked() {
      log.info('logout clicked');
      addDialog({
        title: t('confirmation'),
        message: t('confirm_logout'),
        primaryButtonAction: async () => {
          await EmployeeUserRepo.logout().catch(log.error);
          await deleteData().catch(log.error);
        },
        secondaryButton: t('cancel'),
      });
    }

    async function profileClicked() {
      log.info('Profile button clicked');
      navigation.navigate(RouteNames.DashboardProfile);
    }
  },
  (prev, next) => {
    return (
      prev.options?.title === next.options?.title &&
      prev.route?.name === next.route?.name
    );
  },
);
