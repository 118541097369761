import { useNavigation } from '@react-navigation/native';
import { CommonStyles, SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { StockSchema } from 'ehawker/src/data/schemas/stock/StockSchema';
import { View } from 'react-native';
import { Card, Text } from 'react-native-paper';

import { NavigationProps, RouteNames } from '../../../navigation/Routes';

export interface InventoryItemProps {
  stock: StockSchema;
}

export function InventoryItem({ stock }: InventoryItemProps) {
  const { t } = useTranslate();
  const navigation = useNavigation<NavigationProps>();

  return (
    <Card
      style={{
        marginHorizontal: SPACING.s,
        marginVertical: SPACING.xs,
      }}
      onPress={() =>
        navigation.navigate(RouteNames.StockReceive, {
          _itemcode: stock._itemcode,
        })
      }
    >
      <Card.Content>
        <View style={CommonStyles.row}>
          <View
            style={{
              flex: 3,
            }}
          >
            <Text
              variant="titleMedium"
              style={{
                fontWeight: 'bold',
              }}
            >
              {stock._item}
            </Text>
            <Text variant="titleMedium">{stock._supplier ?? '-'}</Text>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              variant="titleLarge"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {stock._onhandqty}
            </Text>
            <Text variant="titleMedium">{t('in_stock')}</Text>
          </View>
        </View>
      </Card.Content>
    </Card>
  );
}
