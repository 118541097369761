import { useNavigation } from '@react-navigation/native';
import { BarcodeScannerModal } from 'base/src/ui/dialogs/BarcodeScannerModal';
import { useSnackbar } from 'base/src/ui/helpers/SnackbarHelper';
import { useTranslate } from 'base/src/ui/locale/locale';
import { loggerWithTag } from 'base/src/utils/log';
import { useEmployeeUser } from 'ehawker/src/ui/hooks/useEmployeeUser';
import { ApkUpdateDialog } from 'ehawker/src/ui/pages/ApkUpdateDialog';
import * as SplashScreen from 'expo-splash-screen';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { FAB, useTheme } from 'react-native-paper';
import { createMaterialBottomTabNavigator } from 'react-native-paper/react-navigation';

import { DoListTab } from './DeliveryOrder/DoListTab';
import { DashboardTab } from './Home/DashboardTab';
import { InventoryTab } from './Inventory/InventoryTab';
import { ScannedQRDoList } from './Qr/ScannedQRDoList';
import { NavigationProps } from '../../navigation/Routes';

const log = loggerWithTag('DashboardPage.tsx');

export const Tab = createMaterialBottomTabNavigator();

const styles = StyleSheet.create({
  fabContainer: {
    position: 'absolute',
    justifyContent: 'center',
    flexDirection: 'row',
    bottom: 40,
    left: '50%',
  },
});

export function DashboardPage() {
  const [qrScannerOpen, setQrScannerOpen] = useState(false);
  const theme = useTheme();
  const navigation = useNavigation<NavigationProps>();
  const { addSnackbar } = useSnackbar();
  const { t } = useTranslate();
  const user = useEmployeeUser();

  useEffect(() => {
    SplashScreen.hideAsync().catch(log.error);
  }, []);

  function onQrCodeScanned(qrcode: string) {
    log.info('Scanned QR Code Data: ' + qrcode);
    const regex = /(?:stcode=)([^&]+)/;
    const match = qrcode.match(regex);
    if (match?.[1]) {
      const stcode: string = match[1];
      log.info('stcode: ' + stcode);
      setQrScannerOpen(false);
      // @ts-ignore
      navigation.navigate('Scan QR', { stcode });
    } else {
      log.info('stcode parameter not found');
      setQrScannerOpen(false);
      addSnackbar({
        message: t('Invalid QRcode'),
      });
    }
  }

  return (
    <>
      <Tab.Navigator
        safeAreaInsets={{
          bottom: 0,
        }}
        theme={theme}
      >
        <Tab.Screen
          name="Dashboard"
          options={{
            tabBarIcon: 'home',
            tabBarLabel: t('dashboard'),
          }}
          component={DashboardTab}
        />
        <Tab.Screen
          name="Scan QR"
          options={{
            tabBarIcon: 'barcode-scan',
            tabBarLabel: t('scan_qr'),
          }}
          component={(props: any) => <ScannedQRDoList {...props} />}
          listeners={() => ({
            tabPress: () => {
              setQrScannerOpen(true);
            },
          })}
        />
        <Tab.Screen
          name="Inventory"
          options={{
            tabBarIcon: 'archive',
            tabBarLabel: t('inventory'),
          }}
          component={(props: any) => <InventoryTab />} // temp empty component
        />
        <Tab.Screen
          name="DO"
          options={{
            tabBarIcon: 'file-document-multiple-outline',
            tabBarLabel: t('do'),
          }}
          component={(props: any) => <DoListTab {...props} />} // Pass all props to DoListTab
        />
      </Tab.Navigator>

      {/*
      <View style={styles.fabContainer}>
        <FAB
          icon="line-scan"
          style={{
            marginLeft: -28,
          }}
          onPress={() => setQrScannerOpen(true)}
        />
      </View>
      */}

      <BarcodeScannerModal
        visible={qrScannerOpen}
        setVisible={setQrScannerOpen}
        onBarCodeScanned={(imageResult) => {
          onQrCodeScanned(imageResult.data);
        }}
      />
      <ApkUpdateDialog />
    </>
  );
}
