import { NavigationContainer } from '@react-navigation/native';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CommonStyles } from 'base/src/ui/CommonStyles';
import { AppVariantBadge } from 'base/src/ui/components/AppVariantBadge';
import { BackgroundView } from 'base/src/ui/components/BackgroundView';
import { MaxWidthView } from 'base/src/ui/components/MaxWidthView';
import { DialogProvider } from 'base/src/ui/helpers/DialogHelper';
import { ErrorHelperProvider } from 'base/src/ui/helpers/ErrorHelper';
import { useOffline } from 'base/src/ui/helpers/OfflineHelper';
import { SnackbarProvider } from 'base/src/ui/helpers/SnackbarHelper';
import { useFontConfig } from 'base/src/ui/hooks/useFontConfig';
import { useScreenOrientation } from 'base/src/ui/hooks/useScreenOrientation';
import { ErrorPage } from 'base/src/ui/pages/ErrorPage';
import { loggerWithTag } from 'base/src/utils/log';
import { EmployeeUserRepo } from 'ehawker/src/data/repositories/EmployeeUserRepo';
import { useUser } from 'ehawker/src/ui/hooks/useUser';
import { initLocale } from 'ehawker/src/ui/locale/locale';
import EventEmitter from 'eventemitter3';
import { StatusBar } from 'expo-status-bar';
import i18next from 'i18next';
import { atom } from 'jotai';
import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  useColorScheme,
  View,
} from 'react-native';
import ErrorBoundary from 'react-native-error-boundary';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import {
  configureFonts,
  MD3LightTheme as DefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';

import { NavigatorWrapper } from './navigation/NavigatorWrapper';
import { CONSTANTS } from '../utils/constants';
import { FLAG } from '../utils/flag';

const log = loggerWithTag('App.tsx');

export const eventEmitter = new EventEmitter();

export const splashScreenShowingAtom = atom(true);

const queryClient = new QueryClient();

export function App() {
  log.debug('App rendered');
  log.debug(`CONSTANTS: ${JSON.stringify(CONSTANTS)}`);
  log.debug(`FLAG: ${JSON.stringify(FLAG)}`);

  const { fontConfig } = useFontConfig();
  useScreenOrientation();
  useOffline();
  const user = useUser();
  const colorScheme = useColorScheme();
  const colors =
    FLAG.DARK_MODE && colorScheme === 'dark'
      ? require('./theme/dark.json').colors
      : require('./theme/light.json').colors;
  const theme = {
    ...DefaultTheme,
    // Both dark and light theme json are generated from this tool.
    // https://callstack.github.io/react-native-paper/docs/guides/theming/#creating-dynamic-theme-colors
    // Only primary color #075EB2 is provided to the tool and all other colors are generated.
    colors,
    fonts: configureFonts({
      config: fontConfig,
    }),
  };
  const _StrictMode = Platform.OS === 'web' ? React.Fragment : React.StrictMode;

  useEffect(() => initLocale(), []);

  return (
    <_StrictMode>
      <Suspense fallback={<ActivityIndicator />}>
        <I18nextProvider i18n={i18next}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryClientProvider client={queryClient}>
              <GestureHandlerRootView style={CommonStyles.container}>
                <PaperProvider theme={theme}>
                  <BackgroundView>
                    <SafeAreaView style={CommonStyles.container}>
                      <KeyboardAvoidingView
                        style={CommonStyles.container}
                        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                      >
                        <View style={CommonStyles.container}>
                          <AppVariantBadge />
                          <MaxWidthView>
                            <Suspense fallback={<ActivityIndicator />}>
                              <DialogProvider>
                                <SnackbarProvider>
                                  <ErrorHelperProvider
                                    unauthorizedCallback={() => {
                                      EmployeeUserRepo.logout().catch(
                                        log.error,
                                      );
                                    }}
                                  >
                                    <NavigationContainer>
                                      <NavigatorWrapper />
                                    </NavigationContainer>
                                  </ErrorHelperProvider>
                                </SnackbarProvider>
                              </DialogProvider>
                            </Suspense>
                          </MaxWidthView>
                        </View>
                        <StatusBar />
                      </KeyboardAvoidingView>
                    </SafeAreaView>
                  </BackgroundView>
                </PaperProvider>
              </GestureHandlerRootView>
            </QueryClientProvider>
          </ErrorBoundary>
        </I18nextProvider>
      </Suspense>
    </_StrictMode>
  );
}
