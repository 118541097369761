import { FlashList } from '@shopify/flash-list';
import { SPACING } from 'base/src/ui/CommonStyles';
import { useTranslate } from 'base/src/ui/locale/locale';
import { OrderListRequestSchema } from 'ehawker/src/data/schemas/order/OrderListRequestSchema';
import { useEmployeeUser } from 'ehawker/src/ui/hooks/useEmployeeUser';
import { useOrderCountList } from 'ehawker/src/ui/query/useOrderCountList';
import moment from 'moment';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { Avatar, Text, useTheme } from 'react-native-paper';

import { DashboardCount } from './DashboardCount';
import { TabContainer } from '../TabContainer';

const styles = StyleSheet.create({
  usernameContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: SPACING.s,
  },
  username: {
    marginHorizontal: SPACING.xs,
  },
});

export function DashboardTab() {
  const { t } = useTranslate();
  const { colors } = useTheme();
  const today = moment().format('YYYY-MM-DD');
  const avatarStyle = { backgroundColor: colors.secondary };
  const orderListRequest: OrderListRequestSchema = {
    _expectedDateFrom: today,
    _expectedDateTo: today,
  };
  const { orderCountList, refreshOrderCountList } =
    useOrderCountList(orderListRequest);

  const user = useEmployeeUser();

  useEffect(() => {
    const intervalId = setInterval(refreshOrderCountList, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <TabContainer>
      <View
        style={{
          marginVertical: SPACING.s,
          marginHorizontal: SPACING.m,
        }}
      >
        <View style={styles.usernameContainer}>
          <Avatar.Icon style={avatarStyle} size={24} icon="account" />
          <Text style={styles.username} variant="bodyMedium">
            {user?._name}
          </Text>
        </View>
        <Text
          style={{
            marginBottom: SPACING.xs,
          }}
          variant="titleMedium"
        >
          {t('today')}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            height: 300,
          }}
        >
          <FlashList
            data={orderCountList?.data}
            keyExtractor={(item) => item.name}
            estimatedItemSize={6}
            numColumns={3}
            renderItem={({ item }) => (
              <DashboardCount title={item.name} count={item.count} />
            )}
          />
        </View>
      </View>
    </TabContainer>
  );
}
